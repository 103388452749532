import React from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

const Loader = (numOfCols) => {
  numOfCols = numOfCols.numOfCols ?? 8;

  return (
    <Container className="p-1">
        <Row>
            <Col>
                <p className="card-text placeholder-glow">
                <span className={`placeholder col-${numOfCols}`} aria-label='loading'></span>
                </p><p className="card-text placeholder-glow">
                <span className={`placeholder col-${numOfCols-2}`} aria-label='loading'></span>
                </p>
            </Col>
        </Row>
    </Container>
  )
}

export default Loader;