import { createSlice } from '@reduxjs/toolkit';
import { getWeatherByCity, getWeatherByLatLon } from '../thunks/weather';
import { savePosition } from '../utils';

const initialState = {
  loading: true,
  error: undefined,
  data: {}
}
export const setLatLonLocalStorage = (weatherData) => {
    const latLonData = weatherData[0];
    savePosition(latLonData.weatherLat, latLonData.weatherLon);
}
export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    setError: (state, action) => {
      const { payload } = action;
      state.error = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getWeatherByLatLon.pending, (state) => {
      state.error = undefined;
      state.loading = true;
    }),
    builder.addCase(getWeatherByLatLon.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    }),
    builder.addCase(getWeatherByLatLon.rejected, (state, { payload }) => {
      const { message } = payload;
      state.error = message;
      state.loading = false;
    }),
    builder.addCase(getWeatherByCity.pending, (state) => {
      state.error = undefined;
      state.loading = true;
    }),
    builder.addCase(getWeatherByCity.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      setLatLonLocalStorage(payload);
    }),
    builder.addCase(getWeatherByCity.rejected, (state, {payload}) => {
      const { message } = payload;
      state.error = message;
      state.loading = false;
    })
  }
})

// Action creators are generated for each case reducer function
export const { setError } = weatherSlice.actions;

export default weatherSlice.reducer;

