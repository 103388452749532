import { createAsyncThunk } from "@reduxjs/toolkit";
import * as WeatherAPI from '../api/owm';

export const getWeatherByLatLon = createAsyncThunk('weather/getWeatherByLatLon', async ({lat, lon}, { rejectWithValue }) => {
  try {
    return await WeatherAPI.getWeatherByLatLon(lat, lon);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getWeatherByCity = createAsyncThunk('weather/getWeatherByCity', async ({city}, { rejectWithValue }) => {
  try {
    return await WeatherAPI.getWeatherByCity(city);
  } catch (e) {
    return rejectWithValue(e);
  }
});