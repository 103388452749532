export const fetchData = async (url, params) => {
    const fullUrl = `${url}?${params}`
    const rawResponse = await fetch(fullUrl);
    const response = await rawResponse.json();
    if (rawResponse.status === 200 && !response.cod) { // OK
      return response
    }
  
    throw response;
  };
  