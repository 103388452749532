import {
  mdiBinoculars,
  mdiCarBrakeLowPressure,
  mdiSunWireless,
  mdiWater,
  mdiWaterPercent,
  mdiWeatherSunsetDown,
  mdiWeatherSunsetUp,
  mdiWindsock,
} from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useWeather } from '../providers/weatherContext';
import { capitalizeWords } from '../utils/index';
import {
  calcUVIndexClass,
  degToCompass,
  getFeelsLikeString,
  getTemperatureTrend,
  getWeatherIcon,
} from '../utils/weatherUtils';
import Loader from './Loader.tsx';

function MediaCardCurrentConditions() {
  const { weatherData } = useWeather();
  if (weatherData.loading) {
    return (
      <>
        <Loader numOfCols={8} />
        <Loader numOfCols={10} />
      </>
    );
  }
  const fullWeatherInfo = weatherData.data;
  const { current } = fullWeatherInfo;
  const weather = current.weather[0];
  const currentHourWeather = fullWeatherInfo.hourly[0];
  const nextHourWeather = fullWeatherInfo.hourly[1];

  return (
    <>
      <div className='p-2 mb-2'>
        <Row className='justify-content-center'>
          <Col className='ps-0 pe-0 shadow-lg border border-dark rounded-3'>
            <Card className='text-center bg-dark'>
              <Card.Body className='p-3'>
                <div className='d-flex justify-content-center card-text'>
                  <span className='h1 mb-0 me-2 currentWeatherIcon'>
                    {parse(getWeatherIcon(weather.icon))}{' '}
                  </span>
                  <div className='d-flex justify-content-start align-items-center'>
                    <p className='mb-0 justify-content-start'>
                      <span className='h2'>
                        <strong>
                          {Math.round(current.temp)}
                          &deg;C {capitalizeWords(weather.description)}
                        </strong>
                      </span>
                      <span className='h4 fw-lighter'>
                        {' '}
                        {getFeelsLikeString(
                          current.temp,
                          current.feels_like
                        )}{' '}
                        {getTemperatureTrend(
                          currentHourWeather.temp,
                          nextHourWeather.temp
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Row className='p-2 d-flex'>
        <div className='current-conditions text-center'>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <Icon
                path={mdiWindsock}
                size='32px'
              />
              {Math.round(current.wind_speed * 3.6)}
              kph {degToCompass(current.wind_deg)}
              {current.wind_gust && 
              <span className='muted'> ({Math.round(current.wind_gust * 3.6)}kph)</span>
              }
            </li>
            <li className='list-inline-item'>
              <Icon
                path={mdiWaterPercent}
                size='32px'
              />
              {current.humidity}%
            </li>
            <li className='list-inline-item'>
              <Icon
                path={mdiWater}
                size='32px'
              />
              {Math.round(current.dew_point)}
              &deg;
            </li>
            <li className='list-inline-item'>
              <Icon
                path={mdiSunWireless}
                size='32px'
              />
              <span className={calcUVIndexClass(current.uvi)}>
                {Math.round(current.uvi)}
              </span>
            </li>
            <li className='list-inline-item'>
              <Icon
                path={mdiBinoculars}
                size='32px'
              />
              {Math.round(current.visibility / 1000)}
              km
            </li>
            <li className='list-inline-item'>
              <Icon
                path={mdiCarBrakeLowPressure}
                size='32px'
              />
              {Math.round(current.pressure)}
              hPa
            </li>
            <li className='list-inline-item'>
              <Icon
                path={mdiWeatherSunsetUp}
                size='32px'
              />
              {format(current.sunrise * 1000, 'HH:mm')}
            </li>
            <li className='list-inline-item'>
              <Icon
                path={mdiWeatherSunsetDown}
                size='32px'
              />
              {format(current.sunset * 1000, 'HH:mm')}
            </li>
          </ul>
        </div>
      </Row>
    </>
  );
}
export default MediaCardCurrentConditions;
