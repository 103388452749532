import React from 'react';
import { Col, Row } from 'react-bootstrap';
import versionText from '../static/gitInfo.txt';


class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      gitVersion: ''
      }
    }
    componentDidMount() {
      fetch(versionText)
      .then(r => r.text())
      .then(text => {
        this.setState({gitVersion: text})
      });
    }
    render() {

    return <Row className='justify-content-center'>
        <Col>
          <p className='text-center fs-small'>V. {this.state.gitVersion}</p>
        </Col>
      </Row>
  }
}
export default Footer;
