import React from 'react';

import {
  mdiBinoculars,
  mdiSunWireless,
  mdiWaterPercent,
  mdiWindsock,
} from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useWeather } from '../providers/weatherContext';
import { capitalizeWords } from '../utils/index';
import {
  calcHourlyPOP,
  calcUVIndexClass,
  degToCompass,
  getWeatherIcon,
} from '../utils/weatherUtils';
import Loader from './Loader.tsx';

const MAX_HOURLY_ITEMS = 12;

function HourlyWeatherConditionsTiles() {
  const { weatherData } = useWeather();
  if (weatherData.loading) {
    return (
      <>
        <Loader numOfCols={8} />
        <Loader numOfCols={10} />
      </>
    );
  }
  const fullWeather = weatherData.data;
  const hourlyWeatherDetails = fullWeather.hourly;

  return (
    <Accordion flush defaultActiveKey={0}>
      <Row className='g-0 d-flex'>
        <div className='daily-tiles-container text-center'>
          <ul className='list-inline mb-0'>
            {hourlyWeatherDetails
              .slice(0, MAX_HOURLY_ITEMS)
              .map((hourlyData, i) => (
                <li
                  key={hourlyData.dt}
                  className='text-center list-inline-item m-0 p-0 daily-tiles'
                >
                  <Accordion.Item eventKey={i} className='m-1'>
                    <Card
                      as={Accordion.Button}
                      size='sm'
                      bg='dark'
                      className='noArrow p-0 m-0 text-center'
                    >
                      <Card.Header className='noWrap hourlyTime pb-0'>
                        {format(hourlyData.dt * 1000, 'ha')}
                      </Card.Header>
                      <Card.Body className='py-1'>
                        <Card.Title className='mb-0'>
                          <span className='fs-1 mb-0 verticalCenterAlign '>
                            {parse(
                              getWeatherIcon(hourlyData.weather[0].icon),
                            )}
                          </span>
                        </Card.Title>
                        <Card.Text className='pt-2 lh-1'>
                          <span className='hourlyTemp'>
                            {Math.round(hourlyData.temp)}&deg;C{' '}
                          </span>
                          <br />
                          {Math.round(hourlyData.temp)
                            !== Math.round(hourlyData.feels_like) ? (
                              <small className='hourlyFeelsLike'>
                                ( {Math.round(hourlyData.feels_like)}&deg;C)
                              </small>
                            ) : (
                              <small className='hourlyFeelsLike'>&nbsp;</small>
                            )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Accordion.Item>
                </li>
              ))}
          </ul>
        </div>
      </Row>
      <Row className='g-0 d-flex  justify-content-center'>
        {hourlyWeatherDetails
          .slice(0, MAX_HOURLY_ITEMS)
          .map((hourlyData, j) => (
            <Col key={j} className='mx-1 d-flex' md='8'>
              <Accordion.Collapse
                eventKey={j}
                className='daily-tiles-details'
              >
                <Card
                  bg='secondary'
                  className='border-5 shadow-lg border-dark'
                >
                  <Card.Body className='text-center p-0'>
                    <div className='hourly-weather summary'>
                      <p className='mb-0'>
                        {capitalizeWords(
                          hourlyData.weather[0].description,
                        )}
                      </p>
                      <ul className='list-inline mb-1'>
                        <li className='list-inline-item'>
                          <Icon
                            path={mdiSunWireless}
                            size='24px'
                            className='me-1'
                          />
                          <span
                            className={calcUVIndexClass(
                              Math.round(hourlyData.uvi),
                            )}
                          >
                            {Math.round(hourlyData.uvi)}
                          </span>
                        </li>
                        <li className='list-inline-item'>
                          <Icon path={mdiWindsock} size='24px' />
                          {Math.round(hourlyData.wind_speed * 3.6)}kph{' '}
                          {degToCompass(hourlyData.wind_deg)}
                          {Math.round(hourlyData.wind_speed)
                            !== Math.round(hourlyData.wind_gust) ? (
                              <span className='muted'>
                                ({Math.round(hourlyData.wind_gust * 3.6)}kph)
                              </span>
                            ) : (
                              ''
                            )}
                        </li>
                        <li className='list-inline-item'>
                          <Icon path={mdiWaterPercent} size='24px' />
                          {Math.round(hourlyData.humidity)}%
                        </li>
                        <li className='list-inline-item'>
                          <Icon path={mdiBinoculars} size='24px' />
                          {Math.round(hourlyData.visibility / 1000)}km
                        </li>
                        {hourlyData.pop > 0 && (
                          <li className='list-inline-item'>
                            <span className='fw-lighter'>
                              {calcHourlyPOP(hourlyData)}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Accordion.Collapse>
            </Col>
          ))}
      </Row>
    </Accordion>
  );
}
export default HourlyWeatherConditionsTiles;
