import React from 'react';

import {
  mdiSunWireless, mdiWaterPercent, mdiWeatherSunsetDown, mdiWeatherSunsetUp, mdiWindsock,
} from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getFormattedDayOfWeek, oddEvenRowClass } from '../utils/index';
import Loader from './Loader.tsx';

import { useWeather } from '../providers/weatherContext';
import {
  calcDailyPOP,
  calcLeftRange, calcRangerWidth, calcUVIndexClass, calcWeekTemperatureRange, degToCompass, getWeatherIcon,
} from '../utils/weatherUtils';

function DailyWeatherConditions() {
  const { weatherData } = useWeather();
  if (weatherData.loading) {
    return (
      <Loader numOfCols={6} />
    );
  }
  const fullWeatherInfo = weatherData.data;
  const dailyWeather = fullWeatherInfo.daily;
  const temperatureRanges = calcWeekTemperatureRange(dailyWeather);
  const { weeklyLow } = temperatureRanges;

  const { temperatatureRangeRatio } = temperatureRanges;
  const todaysWeather = dailyWeather[0];
  return (
    <Accordion flush defaultActiveKey='' className='my-2'>
      <Row className='justify-content-md-center'>
        <Col md='8'>
          <Card className='border-5 shadow-lg rounded-3 border-dark bg-dark'>
            <Accordion.Item eventKey='0' className='py-0 bg-dark'>
              <Accordion.Header as={Card.Body} className='p-0 '>
                <div className='d-flex justify-content-start card-text '>
                  <span className='fs-1 mb-0 me-2 verticalCenterAlign dailyWeatherIcon'>{parse(getWeatherIcon(todaysWeather.weather[0].icon))}</span>
                  <div className='d-flex justify-content-start align-items-center ms-2'>
                    <div className='mb-0 justify-content-start'>
                      Today: {todaysWeather.summary} with a high of
                      <span className='highTemp'> {Math.round(todaysWeather.temp.max)}&deg;C</span>, and a low of <span className='lowTemp'>{Math.round(todaysWeather.temp.min)}&deg;C</span>.
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {dailyWeather.map((dailyData, i) => (
                  <Accordion key={i} flush defaultActiveKey='' className={oddEvenRowClass(i, '')}>
                    <Accordion.Item eventKey='0' className='py-0'>
                      <Row as={Accordion.Header} key={dailyData.time} className={oddEvenRowClass(i, 'pb-0 pt-0 btn-dark border-top border-1 border-secondary')}>
                        <Col xs='12'>
                          <Row>
                            <Col xs='2' sm='2' className='p-0'>
                              <strong>{(i === 0) ? 'Today' : getFormattedDayOfWeek(dailyData.dt)}</strong><br />
                              <small className='dailyPOP'>POP: {Math.round(dailyData.pop * 100)}%</small>
                            </Col>
                            <Col xs='2' sm='2' className=' text-center'>
                              <span className='fs-1 mb-0 me-2 verticalCenterAlign'>{parse(getWeatherIcon(dailyData.weather[0].icon))}</span>
                            </Col>
                            <Col xs='8' sm='8' className='verticalCenterAlign'>
                              <div className='temperature_range'>
                                <div className='ranger' style={{ left: calcLeftRange(weeklyLow, dailyData.temp.min, temperatatureRangeRatio), width: calcRangerWidth(dailyData.temp.max, dailyData.temp.min, temperatatureRangeRatio) }}>
                                  <span className='temperature_min'>{Math.round(dailyData.temp.min)}&deg;C</span>
                                  <span className='temperature_max'><strong>{Math.round(dailyData.temp.max)}&deg;C</strong></span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className='daily-summary text-center fw-lighter mt-2 mb-0'>{dailyData.summary}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row as={Accordion.Body} className={oddEvenRowClass(i, 'py-2   dailyWeatherDetailsAccordionBody')}>
                        <Col className='px-0 mx-0'>
                          <div className='card bg-secondary border border-1 border-dark'>
                            <div className='card-body '>
                              <ul className='list-inline mb-0 text-center '>
                                <li className='list-inline-item'>
                                  <Icon path={mdiWindsock} size='24px' />{Math.round(dailyData.wind_speed * 3.6)}kph {degToCompass(dailyData.wind_deg)}
                                  {(Math.round(dailyData.wind_speed) !== Math.round(dailyData.wind_gust)) ? <span className='muted'>({Math.round(dailyData.wind_gust * 3.6)}kph)</span> : ''}
                                </li>
                                <li className='list-inline-item'><Icon path={mdiWaterPercent} size='24px' />{Math.round(dailyData.humidity)}%</li>
                                <li className='list-inline-item me-1'>
                                  <Icon path={mdiSunWireless} size='24px' className='me-1' /><span className={calcUVIndexClass(Math.round(dailyData.uvi))}>{Math.round(dailyData.uvi)}</span>
                                </li>
                                <li className='list-inline-item me-1'><Icon path={mdiWeatherSunsetUp} size='24px' /><small>{format(dailyData.sunrise * 1000, 'HH:mm')}</small> <Icon path={mdiWeatherSunsetDown} size='24px' /><small>{format(dailyData.sunset * 1000, 'HH:mm')}</small></li>
                                <li className='list-inline-item'><small>{calcDailyPOP(dailyData)}</small></li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Item>

                  </Accordion>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        </Col>
      </Row>
    </Accordion>
  );
}
export default DailyWeatherConditions;
