export const BASE_URL = 'https://m.mehdwan.com/';
export const MAPBOX_API_URL = BASE_URL + 'api.mapbox.php';
export const OPENWEATHERMAP_API_URL = BASE_URL + 'api.owm.php';

export const URL_PARAM_LAT = "lat";
export const URL_PARAM_LON = "lon";
export const URL_LOCATION = "urlLocation";

export const LOCAL_STORAGE_KEY_WELCOME_MODAL = "welcomeModal";
export const LOCAL_STORAGE_KEY_GPS_POSITION = "gps_position";

export const ERROR_BROWSER_GEOLOCATION_OFF = "It seems like your browser does not support HTML5 geolocation. Please install a different browser and enable javascript";
export const ERROR_INVALID_LAT_LON = "Invalid latitude or longitude, make sure your browser support Javascript and HTML";
export const ERROR_INVALID_CITY = "Invalid city. Try with another city";

export const WEATHER_ICONS = [
    {
        icon: '01d',
        svg: 'clear-day.svg',
        iconFont: '<i class="wi wi-day-sunny wi-fw"></i>'
    },
    {
        icon: '02d',
        svg: 'partly-cloudy-day.svg',
        iconFont: '<i class="wi wi-day-cloudy wi-fw"></i>'
    },
    {
        icon: '03d',
        svg: 'cloudy.svg',
        iconFont: '<i class="wi wi-day-cloudy wi-fw"></i>'
    },
    {
        icon: '04d',
        svg: 'cloudy.svg',
        iconFont: '<i class="wi wi-cloud wi-fw"></i>'
    },
    {
        icon: '09d',
        svg: 'rain.svg',
        iconFont: '<i class="wi wi-showers wi-fw"></i>'
    },
    {
        icon: '10d',
        svg: 'rain.svg',
        iconFont: '<i class="wi wi-day-rain wi-fw"></i>'
    },
    {
        icon: '11d',
        svg: 'thunderstorm.svg',
        iconFont: '<i class="wi wi-day-lightning wi-fw"></i>'
    },
    {
        icon: '13d',
        svg: 'snow.svg',
        iconFont: '<i class="wi wi-snowflake-cold wi-fw"></i>'
    },
    {
        icon: '50d',
        svg: 'fog.svg',
        iconFont: '<i class="wi wi-day-fog wi-fw"></i>'
    },
    {
        icon: '01n',
        svg: 'clear-night.svg',
        iconFont: '<i class="wi wi-night-clear wi-fw"></i>'
    },
    {
        icon: '02n',
        svg: 'partly-cloudy-night.svg',
        iconFont: '<i class="wi wi-night-alt-cloudy wi-fw"></i>'
    },
    {
        icon: '03n',
        svg: 'cloudy.svg',
        iconFont: '<i class="wi wi-night-alt-cloudy wi-fw"></i>'
    },
    {
        icon: '04n',
        svg: 'cloudy.svg',
        iconFont: '<i class="wi wi-cloud wi-fw"></i>'
    },
    {
        icon: '09n',
        svg: 'rain.svg',
        iconFont: '<i class="wi wi-showers wi-fw"></i>'
    },
    {
        icon: '10n',
        svg: 'rain.svg',
        iconFont: '<i class="wi wi-night-rain wi-fw"></i>'
    },
    {
        icon: '11n',
        svg: 'thunderstorm.svg',
        iconFont: '<i class="wi wi-night-lightning wi-fw"></i>'
    },
    {
        icon: '13n',
        svg: 'snow.svg',
        iconFont: '<i class="wi wi-snowflake-cold wi-fw"></i>'
    },
    {
        icon: '50n',
        svg: 'fog.svg',
        iconFont: '<i class="wi wi-night-fog wi-fw"></i>'
    },
];

