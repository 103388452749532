import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/AccordionBody';
import AccordionButton from 'react-bootstrap/AccordionButton';
import AccordionHeader from 'react-bootstrap/AccordionHeader';
import AccordionItem from 'react-bootstrap/AccordionItem';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useWeather } from '../providers/weatherContext';
import { capitalizeWords, getFormattedDateTime } from '../utils/index';
import { getAlertType } from '../utils/weatherUtils';
import Loader from './Loader.tsx';

export const customAccordion = (weatherAlertsDetails) => {
  const [show, setShow] = useState(false);
  return weatherAlertsDetails.map((weatherAlert, i) => (
    <div key={i}>
      <Alert
        onClick={() => setShow(false)}
        show={show}
        onClose={() => setShow(false)}
        variant={getAlertType(weatherAlert)}
        role='alert'
        dismissible
      >
        <Alert.Heading>{capitalizeWords(weatherAlert.event)}</Alert.Heading>
        <p style={{ whiteSpace: 'pre-line' }}>
          <strong>Alert Time:</strong>{' '}
          {getFormattedDateTime(weatherAlert.start)}
          <br />
          <strong>Description: </strong>
          {weatherAlert.description}
          <br />
          <strong>Expires:</strong> {getFormattedDateTime(weatherAlert.end)}
          <br />
        </p>
        <Button
          onClick={() => setShow(false)}
          variant={getAlertType(weatherAlert)}
        >
          Close
        </Button>
      </Alert>
      {!show && (
        <div className='d-grip gap-2'>
          <Button
            className='w-100 mb-3'
            variant={getAlertType(weatherAlert)}
            size='lg'
            onClick={() => setShow(true)}
          >
            {capitalizeWords(weatherAlert.event)}
          </Button>
        </div>
      )}
    </div>
  ));
};
export const generateAccordion = (weatherAlertsDetails) =>
  weatherAlertsDetails.map((weatherAlert, i) => (
    <div key={i}>
      <Alert
        as={AccordionItem}
        eventKey={i}
        variant={getAlertType(weatherAlert)}
        role='alert'
        dismissible
      >
        <Alert.Heading as={AccordionHeader}>
          {capitalizeWords(weatherAlert.event)}
        </Alert.Heading>
        <p
          as={AccordionBody}
          style={{ whiteSpace: 'pre-line' }}
        >
          <strong>Alert Time:</strong>{' '}
          {getFormattedDateTime(weatherAlert.start)}
          <br />
          <strong>Description: </strong>
          {weatherAlert.description}
          <br />
          <strong>Expires:</strong> {getFormattedDateTime(weatherAlert.end)}
          <br />
        </p>
        <Button
          as={AccordionButton}
          variant={getAlertType(weatherAlert)}
        >
          Close
        </Button>
      </Alert>
    </div>
  ));
export function WeatherAlert() {
  const { weatherData } = useWeather();
  if (weatherData.loading) {
    return <Loader />;
  }
  const weatherAlertsDetails = weatherData.data.alerts;

  return (
    <Accordion defaultActiveKey=''>
      {customAccordion(weatherAlertsDetails)}
    </Accordion>
  );
}
