import { ERROR_INVALID_CITY, ERROR_INVALID_LAT_LON, OPENWEATHERMAP_API_URL } from '../utils/constants';
import { fetchData } from './common';

 export const getWeatherByLatLon = async (lat, lon) => {
    let message;
    let error;
    if (!lat || !lon) {
       message = ERROR_INVALID_LAT_LON;
    }
    if(message !== undefined) {
        error = {message: message}
        throw(error);
    }
    
    const baseUrl = OPENWEATHERMAP_API_URL;
    const params = `l=${lat},${lon}`;
    return await fetchData(baseUrl, params);
  };
  
export const getWeatherByCity = async (city_) => {
    if (!city_ || city_ === '' || city_ === ' ') {
      const error = { message: ERROR_INVALID_CITY };
      throw(error);
    }
  
    const baseUrl = OPENWEATHERMAP_API_URL;
    const params = `l=${city_}`;
    return await fetchData(baseUrl, params);
  };
  