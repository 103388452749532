import parse from 'html-react-parser';
import { WEATHER_ICONS } from './constants';

export const getWeatherIcon = (iconCode) => {
  const iconToReturn = WEATHER_ICONS.filter(
    (weatherIcons) => weatherIcons.icon == iconCode,
  );
  return iconToReturn[0].iconFont;
};
export const degToCompass = (num) => {
  const val = Math.floor(num / 22.5 + 0.5);
  const arr = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ];
  return arr[val % 16];
};
export const getSnowCoefficient = (temperature) => {
  let snowCoefficient = 0;
  if (temperature > 1 && temperature <= 7) {
    snowCoefficient = 0.1;
  }
  if (temperature > -3 && temperature <= 1) {
    snowCoefficient = 10;
  } else if (temperature > -6 && temperature <= -3) {
    snowCoefficient = 15;
  } else if (temperature > -9 && temperature <= -6) {
    snowCoefficient = 20;
  } else if (temperature > -12 && temperature <= -9) {
    snowCoefficient = 30;
  } else if (temperature > -18 && temperature <= -12) {
    snowCoefficient = 40;
  } else if (temperature > -29 && temperature <= -18) {
    snowCoefficient = 50;
  } else if (temperature <= -29) {
    snowCoefficient = 100;
  }
  return snowCoefficient;
};
export const calcUVIndexClass = (uvIndex) => {
  let uvIndexClass = 'badge';
  if (uvIndex > 0 && uvIndex < 3) {
    uvIndexClass = 'badge bg-success';
  } else if (uvIndex >= 3 && uvIndex < 4) {
    uvIndexClass = 'badge bg-info text-dark';
  } else if (uvIndex >= 4 && uvIndex < 6) {
    uvIndexClass = 'badge bg-warning text-dark';
  } else if (uvIndex >= 6) {
    uvIndexClass = 'badge bg-danger';
  }
  return uvIndexClass;
};
export const getTemperatureTrend = (
  currentHourTemperature,
  nextHourTemperature,
) => {
  const temperatureDifferential = Math.round(nextHourTemperature) - Math.round(currentHourTemperature);
  if (temperatureDifferential < 0) {
    return <>&#8744;</>;
  }
  if (temperatureDifferential > 0) {
    return <>&#8743;</>;
  }
  return <>&mdash;</>;
};
export const getFeelsLikeString = (currentTemp, currentFeelsLike) => {
  if (Math.round(currentTemp) != Math.round(currentFeelsLike)) {
    return `(${Math.round(currentFeelsLike)}°C)`;
  }
};

export const calcHourlyPOP = (hourlyData) => {
  let hourlySnow = '';
  let hourlyRain = '';
  const popStr = `POP: ${Math.round(hourlyData.pop * 100)}% `;

  if (hourlyData.snow !== undefined) {
    let tempSnow = hourlyData.snow;
    if (hourlyData.snow['1h'] !== undefined) {
      tempSnow = hourlyData.snow['1h'];
    }
    const snowCoefficient = getSnowCoefficient(hourlyData.temp);
    let snow = Math.round((tempSnow / 25.4) * snowCoefficient * 2.54);
    if (snow == 0) snow = 1;
    hourlySnow = `Snow: ${snow}cm`;
    +' ';
  }
  if (hourlyData.rain !== undefined) {
    let tempRain = hourlyData.rain;
    if (hourlyData.rain['1h'] !== undefined) {
      tempRain = hourlyData.rain['1h'];
    }
    hourlyRain = `Rain: ${Math.round(tempRain * 10) / 10}mm`;
  }
  return parse(popStr + hourlyRain + hourlySnow);
};
export const calcDailyPOP = (dailyData) => {
  let dailySnow = ' ';
  let dailyRain = '';
  if(dailyData.snow !== undefined || dailyData.rain !== undefined) {
    if(dailyData.snow !== undefined) {
      let lowSnowCoefficient = getSnowCoefficient(dailyData.temp.min)
      let lowSnow = Math.round(((dailyData.snow/25.4)*(lowSnowCoefficient))*2.54);
      let highSnowCoefficient = getSnowCoefficient(dailyData.temp.max)
      let highSnow = Math.round(((dailyData.snow/25.4)*(highSnowCoefficient))*2.54);

      if(highSnow==0)
        highSnow =1;
      if (lowSnow==0)
        lowSnow = 1;
      if(highSnow == lowSnow) {
        dailySnow = '<strong>Snow: </strong>'+highSnow+'cm ';
      } else {
        dailySnow = '<strong>Snow: </strong>'+highSnow+" - "+lowSnow+"cm ";
      }

    }
    if(dailyData.rain !== undefined) {
      dailyRain = '<strong>Rain: </strong>'+Math.round(dailyData.rain*10)/10+'mm ';
    }
    return parse(dailyRain + dailySnow);
  }
}
export const calcWeekTemperatureRange = (dailyWeather) => {
  let weeklyLow = 9999;
  let weeklyHigh = -9999;
  const temperatureRanges = [];
  dailyWeather.map((dailyData) => {
    if (weeklyLow > dailyData.temp.min) {
      weeklyLow = Math.round(dailyData.temp.min);
    }
    if (weeklyHigh < dailyData.temp.max) {
      weeklyHigh = Math.round(dailyData.temp.max);
    }
  });
  const temperatatureRange = Math.round(100 / (weeklyHigh - weeklyLow));
  temperatureRanges.weeklyLow = weeklyLow;
  temperatureRanges.weeklyHigh = weeklyHigh;
  temperatureRanges.temperatatureRangeRatio = temperatatureRange;

  return temperatureRanges;
};
export const calcLeftRange = (weeklyLow, dailyLow, temperatureRangeRatio) => `${(Math.round(dailyLow) - weeklyLow) * temperatureRangeRatio + 15}%`;
export const calcRangerWidth = (dailyHigh, dailyLow, temperatureRangeRatio) => (
  `${(Math.round(dailyHigh) - Math.round(dailyLow)) * temperatureRangeRatio}%`
);
export const getAlertType = (alert) => {
  if (
    alert.tags.includes('warning')
    && alert.event.toUpperCase().includes('WATCH') === false
  ) {
    alertClass = 'danger';
  } else if (
    alert.tags.includes('warning')
    && alert.event.toUpperCase().includes('WATCH') === true
  ) {
    alertClass = 'warning';
  } else if (alert.tags.includes('watch')) {
    alertClass = 'warning';
  }
  return 'danger';
};
