import { format } from 'date-fns';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useWeather } from '../providers/weatherContext';
import { resetApp } from '../utils/index.js';
import Loader from './Loader.tsx';

const SearchForm = () => {
  const { city, weatherData } = useWeather();
  let latLonData;
  let searchCity;
  let current;
  if (weatherData.loading) {
    return (
      <>
        <Loader numOfCols={8} />
        <Loader numOfCols={10} />
      </>
    );
  }
  const error = weatherData.error;
  
  if(!error) {
    const fullWeatherInfo = weatherData.data;
    current = fullWeatherInfo.current;
    latLonData = weatherData.data[0];  
    searchCity = latLonData.observedLocation;
  } else {
    searchCity = city;
  }

  const renderRefreshLink = () => {
    if (!error) {
      return (
        <>
          <Button variant='secondary' onClick={refreshAndReload} id='refresh' className='sm'>
            <span className='fas fa-sync-alt font-weight-bolder'>
              <strong>&#x21BB;</strong>
            </span>{' '}
            <small id='last-updated'>
              Updated:{' '}
              {format(current.dt * 1000, 'EEEE LLLL d, yyyy @ h:mmaaa')}
            </small>{' '}
          </Button>
          <br />
        </>
      );
    }
  };

  const onLocationSearchFormSubmit = (event) => {
    resetApp();
    event.preventDefault();
    window.location.href = window.location.origin + '/' + searchCity;
  };
  const handleInputChanged = (event) => {
    event.preventDefault;
    searchCity = event.target.value;
  };
  const handleButtonClicked = () => {
    window.location.href = window.location.origin + '/' + searchCity;
  };
  const refreshAndReload = (event) => {
    event.preventDefault();
    event.stopPropagation();
    resetApp();
    window.location.href = window.location.origin;
  };
  const renderSearchForm = () => {
    return (
      <Row className='mb-2'>
        <Col>
          <Row className='mb-2'>
            <Col>
              <Form onSubmit={onLocationSearchFormSubmit}>
                <Form.Group className='mb-0' controlId='currentLocation'>
                  <Row>
                    <Col xs className='pe-0'>
                      <Form.Label className='float-start me-2 mt-1'>
                        Location:{' '}
                      </Form.Label>
                      <div className='ps-0 float-start'>
                        <Form.Control
                          style={{
                            height: '16px',
                            fontSize: '60%',
                            width: '185px',
                          }}
                          className='form-control form-control-sm'
                          type='text'
                          onChange={handleInputChanged}
                          placeholder={searchCity}
                        />
                      </div>
                      <Button
                        variant='primary'
                        className='float-start ms-2 mt-0'
                        style={{
                          height: '24px',
                          lineHeight: '10px',
                          fontSize: '80%',
                        }}
                        type='submit'
                        size='sm'
                        onClick={handleButtonClicked}
                      >
                        Go
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
              {renderRefreshLink()}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return <>{renderSearchForm()}</>;
};
export default SearchForm;
